.cards-tools {
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 20px;
  display: grid;
}

.ant-card {
  box-shadow: 0 1px 1px 0 rgba(114, 114, 116, 0.12);
  height: 240px;
}

.card-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
}

.head .tag {
  margin: 0px !important;
}

.card-description {
  margin: 15px 0px;
}

.ant-card-bordered:hover {
  border: 1px solid #ff4e4e;
}

.test-tool {
  color: #ff4e4e;
  text-decoration: underline;
}

.tools {
  margin: 60px 0px;
  width: 820px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.tag-container {
  min-height: 30px;
}

@media screen and (max-width: 991px) {
  .tools {
    width: auto;
    margin: 50px 20px;
  }
  .cards-tools {
    grid-template-columns: none;
  }
}
