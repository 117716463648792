@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
::-moz-selection {
  background: #f7a4a4;
}

::selection {
  background: #f7a4a4;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-title {
  font-size: 38px;
  font-weight: 900;
  text-align: center;
  color: #3c3c3c;
  margin-top: 100px;
}

.secondary-title {
  font-size: 25px;
  text-align: center;
  color: #677294;
}

@media screen and (max-width: 991px) {
  .main-title {
    font-size: 30px;
    margin-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .secondary-title {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.input-row {
  margin-top: 60px;
}

.loading-message {
  text-align: center;
  font-size: 25px;
  color: #3c3c3c;
  margin-top: 20px;
}

.loading-message-serp {
  text-align: center;
  font-size: 25px;
  color: #3c3c3c;
  margin-top: 80px;
}

div.form {
  display: block;
  text-align: center;
}

form .form-audit {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

input {
  height: 40px;
}

button {
  height: 40px;
}

.input-businessName {
  border: none;
  width: 500px;
  border-radius: 3px 0px 0px 3px;
  padding-left: 20px;
}

.google-places-autocomplete__input {
  border: none;
  width: 500px;
  border-radius: 3px 0px 0px 3px;
  padding-left: 20px;
  height: 60px;
  font-size: 17px;
}

.suggestions-container {
  background-color: #fff;
  color: #2b4763;
  border-radius: 2px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.suggestion {
  text-align: left;
  padding: 5px;
}

.suggestion:hover {
  cursor: pointer;
  background-color: rgb(255, 123, 123);
  color: #fff;
}

.suggestion > i {
  padding-right: 5px;
}

.submit-button {
  border-radius: 0px 3px 3px 0px;
  width: 120px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  height: 60px;
  font-size: 17px;
}

.submit-button:hover {
  cursor: pointer;
}

.ant-select {
  width: 200px;
  height: 40px;
}

.submit-button:hover {
  background-color: rgb(255, 123, 123);
}

.result {
  margin-top: 90px;
}

.form-audit .ant-col-12 {
  width: auto;
}

.result > Row {
  padding: 10px 80px;
}

.result-phrase {
  color: #fff;
  font-size: 25px;
  text-align: left;
  margin-top: 30px;
}

.container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.container > .companyInfo {
  width: 900px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  color: #3c3c3c;
  font-weight: bold;
  margin-top: 40px;
  padding-bottom: 30px;
  box-shadow: 0 1px 1px 0 rgba(114, 114, 116, 0.12);
}

.container > .businessInfo.companyInfo {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 20px;
}

div.progress {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.container > .companyGMB {
  margin-top: 30px;
}

.panel-header {
  color: #fff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #677294;
}
.ant-collapse {
  margin-top: 30px;
}

.ant-progress-circle .ant-progress-text {
  /* background-color: #52c41a30; */
  padding-top: 40px;
  padding-bottom: 40px;
  text-shadow: 0 0 22px;
}

.ant-select-selection--single .ant-select-selection__rendered {
  padding: 3px;
}

.ant-select-selection--single {
  height: 40px;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d942;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d942;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.tag {
  padding: 5px 10px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto 10px 0px;
  font-size: 14px;
}

.steps-improve {
  color: #3c3c3c;
}

.ant-collapse-content {
  color: #677294;
}

.contact {
  font-size: 16px;
  text-align: center;
}

.new-search-button {
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px 20px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  margin: 20px auto;
}

.new-search-button:hover {
  background-color: rgb(255, 123, 123);
  color: #fff;
}

.cta-section {
  margin: 50px auto;
  text-align: center;
}

.get-competitors {
  border-radius: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 20px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  margin: 20px auto;
  font-size: 15px;
}

.table-competitors {
  width: 100%;
  margin-top: 30px;
}

.header-table {
  color: #fff;
  font-size: 15px;
}

.form-audit .input-row {
  display: inline-block;
}
@media screen and (max-width: 991px) {
  .form-audit .input-row {
    display: grid;
  }
  .form-audit .ant-col-12 {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-col-6 {
    width: 100%;
  }
  .ant-col-18 {
    margin-top: 20px;
    width: 100%;
  }
  .company-description {
    text-align: center;
  }
  .input-col {
    width: 100%;
  }
  .tag {
    margin-left: auto;
    margin-right: auto;
  }
  .input-businessName {
    width: 300px;
    border-radius: 3px;
    margin-bottom: 5px;
  }
  .google-places-autocomplete__input {
    width: 300px;
    border-radius: 3px;
  }
  .submit-button {
    width: 300px;
    border-radius: 3px;
  }
  .suggestions-container {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .container > .companyInfo {
    width: 90%;
    padding: 30px 10px;
  }
}

.company-description {
  text-align: left;
}

.avatar {
  margin-top: 15px;
}

.title-card {
  color: #3c3c3c;
  font-size: 22px;
  padding: 20px 40px;
}

.body-card {
  padding: 10px 40px 30px 40px;
  color: #677294;
}

.cta-card {
  padding: 30px;
}
.valid-logo {
  width: 18px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #ff4e4e;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #ff4e4e;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #ff4e4e;
}

.ant-tabs-ink-bar {
  background-color: #ff4e4e4d !important;
}

:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  color: #ff4e4e;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 195px);
  grid-gap: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.card-stat {
  padding: 20px;
  border: 1px solid #8080802e;
  border-radius: 3px;
  text-align: center;
}
.green {
  color: #0ace6a;
}

.red {
  color: #ff4e4e;
}

.orange {
  color: #ffa300;
}

.criteria {
  font-size: 30px;
  margin-top: 30px;
}

.block-audit {
  padding: 10px 30px;
}

.title-secondary {
  margin: 30px 0px;
}

@media screen and (max-width: 991px) {
  .company-description {
    text-align: center;
  }
  .cards {
    display: block;
  }
  .card-stat {
    margin-bottom: 20px;
  }
  .criteria {
    margin-top: 20px;
  }
}

.local-audit-container > .company-info > .heading-audit {
  font-size: 18px;
  opacity: 0.8;
}

.local-audit-container > .company-info > .heading-audit > .company-name {
  opacity: 1;
  color: #000000;
}

.date-data {
  font-size: 14px;
  float: right;
}

.fetch-date {
  display: inline-block;
  font-weight: 300;
}

.update-audit {
  border: 0;
  background-color: transparent;
  padding: 5px;
  margin-left: 10px;
  box-shadow: none;
}

.update-audit:hover {
  background-color: transparent;
  font-weight: bold;
}

.update-audit:active {
  background-color: transparent;
}

.featureblock-container {
  box-shadow: rgba(114, 114, 116, 0.12) 0px 1px 1px 0px;
  /* padding: 30px 20px; */
  border-radius: 2px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  margin-bottom: 20px;
}

.featureblock-container-info {
  box-shadow: rgba(114, 114, 116, 0.12) 0px 1px 1px 0px;
  /* padding: 30px 20px; */
  border-radius: 2px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.criteria-status {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 200;
}

.sumup-category {
  margin: 30px 20px;
}

.progress-category {
  text-align: center;
}

.criteria-audit {
  margin: 20px;
  padding-top: 40px;
  border-top: 1px solid #8080801f;
}
.criteria-description {
  font-weight: 100;
  margin-bottom: 30px;
}
.short-link > input {
  width: 350px;
  margin-top: 20px;
  height: 40px !important;
}

.description-table {
  margin: 30px 0px;
}

.table-competitors-audit {
  margin: 30px 20px;
  border-top: 1px solid #8080801f;
  padding-top: 40px;
  /* margin: 40px 0px; */
}

.text-switch {
  padding-left: 10px;
  font-weight: 600;
}

.details-competitors-audit {
  border-top: 1px solid #8080801f;
  padding-top: 40px;
  margin: 40px 0px;
}

.audit-competitors {
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 20px;
  display: grid;
}

.chart-competitors {
  margin: 60px 20px;
  height: 300px;
}

.position-mapping {
  color: #000000;
  font-size: 16px;
}

.legend-graph {
  margin: 10px 0px;
  display: block;
  text-align: center;
}
.legend-square {
  width: 30px;
  height: 10px;
  border: 1px solid #ff4e4e;
  background-color: rgba(255, 77, 77, 0.58);
  display: inline-block;
}

.export-pdf > a {
  border: 1px solid #ff4e4e !important;
  color: #ff4e4e !important;
  background-color: transparent !important;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.export-pdf {
  display: flex;
  vertical-align: middle;
  float: right;
}

.duplicate-button {
  height: 50px;
  width: 200px;
  font-size: 15px;
}

.duplicates-found {
  font-size: 17px;
  font-weight: 600;
}

.refresh-duplicates {
  border: none !important;
  float: right;
  margin-top: -5px;
}

.featureblock-container {
  font-weight: 100;
}

.anticon.anticon-star.star {
  color: #ffa300;
}

.lock-overview-container {
  background-image: url(/static/media/locker.37044022.png);
  background-size: cover;
  height: 200px;
  display: inline-block;
  text-align: center;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  /* padding: 20px; */
}

.locked-btn {
  margin: 60px 0px 20px 0px;
  align-items: center;
  color: #fff;
  background-color: #ff4e4e;
  border: 1px solid #ff4e4e;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.locked-btn:hover {
  color: #ff4e4e;
  background-color: #fff;
  border: 1px solid #ff4e4e;
  opacity: 0.6;
}

.report-link {
  font-size: 16px;
}

.carousel-img {
  display: flex !important;
  justify-content: center;
}
.carousel-section {
  display: block;
}
@media only screen and (max-width: 940px) {
  .export-pdf {
    float: left;
    margin-top: 5px;
  }
  .display-evolution {
    display: none;
  }
  .locked-btn {
    font-size: 14px;
  }
  .carousel-section {
    display: none;
  }
}

.audit #g-recaptcha > div {
  margin-left: auto;
  margin-right: auto;
}

.info-stats {
  max-width: 900px;
  /* display: flex; */
  /* justify-content: center; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 4px;
}

.stats-des {
  font-size: 17px;
}

.stats-figure {
  font-size: 22px;
  font-weight: 600;
}

.stats-picto {
  height: 50px;
}
.stats-picto > img {
  width: 40px;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
}

.logo-client {
  padding: 10px 30px;
}

.ant-row.ant-form-item {
  margin-bottom: 5px;
}

.ant-input.settings-input {
  height: 38px;
  border-radius: 2px;
  font-size: 14px;
}

.ant-input.settings-input::-webkit-input-placeholder {
  font-size: 14px;
}

.ant-input.settings-input::-moz-placeholder {
  font-size: 14px;
}

.ant-input.settings-input:-ms-input-placeholder {
  font-size: 14px;
}

.ant-input.settings-input::-ms-input-placeholder {
  font-size: 14px;
}

.ant-input.settings-input::placeholder {
  font-size: 14px;
}

.ant-input-number.settings-input {
  border-radius: 2px;
  width: 50%;
}

.submit-form {
  background-color: #ff4e4e;
  border-color: #ff4e4e;
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.submit-form:hover {
  background-color: #fff;
  border-color: #ff4e4e;
  color: #ff4e4e;
}

.logo-form {
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: flex-end;
  opacity: 0.5;
}

.logo-form img {
  width: 20px;
  margin: 0px 3px 0px 10px;
}

@media screen and (max-width: 991px) {
  .logos {
    display: block;
  }
  .logo-form img {
    margin: 10px 3px 0px 10px;
  }
}

.local-serp form {
  margin-top: 70px;
  margin-bottom: 70px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.ant-form-item-label {
  display: none;
}

.local-serp input {
  height: 50px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
  border: none;
}

.ant-input {
  font-size: 17px;
}
.ant-input:focus,
.ant-input:active {
  border-color: #ff4e4e !important;
  box-shadow: none;
}

.ant-input::-webkit-input-placeholder {
  color: grey;
  font-size: 17px;
}

.ant-input::-moz-placeholder {
  color: grey;
  font-size: 17px;
}

.ant-input:-ms-input-placeholder {
  color: grey;
  font-size: 17px;
}

.ant-input::-ms-input-placeholder {
  color: grey;
  font-size: 17px;
}

.ant-input::placeholder {
  color: grey;
  font-size: 17px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ff4e4e;
  border-color: #ff4e4e;
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #ff4e4e;
  border-color: #ff4e4e;
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #ff4e4e;
}

.ant-radio-button-wrapper:hover {
  color: #ff4e4e;
}

.ant-select-selection--single {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-select-selection:hover {
  border-color: #f9727247;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f9727247;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f9727247;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #f9727247;
}

.ant-select-selection-selected-value {
  font-size: 17px;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: none;
}

.ant-select-selection {
  border: none;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
}

.ant-radio-button-wrapper {
  height: 50px !important;
  padding-top: 5px;
}

.ant-btn-primary {
  background-color: #ff4e4e;
  border-color: #ff4e4e;
  font-size: 17px;
  border-radius: 2px;
  box-shadow: none;
  height: 50px;
  width: 200px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: rgb(255, 123, 123);
  border-color: rgb(255, 123, 123);
}

@media screen and (max-width: 991px) {
  .local-serp form {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0px 20px;
  }
}

.container-search-links {
  width: 800px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
  color: #3c3c3c;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0 1px 1px 0 rgba(114, 114, 116, 0.12);
}

.title-results {
  font-size: 22px;
}

.subtitle-results {
  font-size: 17px;
  margin-top: 30px;
  text-align: center;
  color: #677294;
}

.links {
  margin-top: 40px;
}
.pagination-google {
  width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pagination-links {
  display: inline-block;
  margin-left: -19px;
}

.link-page {
  /* margin: 0px 8px; */
  padding: 0px 8.6px;
}

.table-summary {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

th {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 150px;
}

td {
  font-weight: 400;
}

.summary-list {
  text-align: left;
  margin-top: 40px;
  display: none;
}

li {
  list-style: none;
}

.li-summary {
  font-weight: 400;
}

@media screen and (max-width: 991px) {
  .container-search-links {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 30px 20px;
  }
  .summary-list {
    display: block;
  }

  .table-summary {
    display: none;
  }
  .pagination-google {
    width: 280px;
  }
  .link-page {
    padding: 0px 4.5px;
  }
}

.cards-tools {
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 20px;
  display: grid;
}

.ant-card {
  box-shadow: 0 1px 1px 0 rgba(114, 114, 116, 0.12);
  height: 240px;
}

.card-title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
}

.head .tag {
  margin: 0px !important;
}

.card-description {
  margin: 15px 0px;
}

.ant-card-bordered:hover {
  border: 1px solid #ff4e4e;
}

.test-tool {
  color: #ff4e4e;
  text-decoration: underline;
}

.tools {
  margin: 60px 0px;
  width: 820px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.tag-container {
  min-height: 30px;
}

@media screen and (max-width: 991px) {
  .tools {
    width: auto;
    margin: 50px 20px;
  }
  .cards-tools {
    grid-template-columns: none;
  }
}

.navbar {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 0 100px;
}

.logo {
  float: left;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

.logo > p {
  margin: 0px;
}

.logo > a {
  color: #fff;
}

.raq {
  float: right;
}

@media screen and (max-width: 991px) {
  .navbar {
    width: 100%;
    padding: 0px;
  }
  .logo {
    text-align: center;
    float: unset;
  }
  .raq {
    display: none;
  }
}

