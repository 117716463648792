.main-title {
  font-size: 38px;
  font-weight: 900;
  text-align: center;
  color: #3c3c3c;
  margin-top: 100px;
}

.secondary-title {
  font-size: 25px;
  text-align: center;
  color: #677294;
}

@media screen and (max-width: 991px) {
  .main-title {
    font-size: 30px;
    margin-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .secondary-title {
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
