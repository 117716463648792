.container-search-links {
  width: 800px;
  height: fit-content;
  background-color: #fff;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 18px;
  color: #3c3c3c;
  font-weight: bold;
  margin-top: 40px;
  margin-bottom: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  box-shadow: 0 1px 1px 0 rgba(114, 114, 116, 0.12);
}

.title-results {
  font-size: 22px;
}

.subtitle-results {
  font-size: 17px;
  margin-top: 30px;
  text-align: center;
  color: #677294;
}

.links {
  margin-top: 40px;
}
.pagination-google {
  width: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pagination-links {
  display: inline-block;
  margin-left: -19px;
}

.link-page {
  /* margin: 0px 8px; */
  padding: 0px 8.6px;
}

.table-summary {
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

th {
  width: fit-content;
  min-width: 150px;
}

td {
  font-weight: 400;
}

.summary-list {
  text-align: left;
  margin-top: 40px;
  display: none;
}

li {
  list-style: none;
}

.li-summary {
  font-weight: 400;
}

@media screen and (max-width: 991px) {
  .container-search-links {
    width: fit-content;
    padding: 30px 20px;
  }
  .summary-list {
    display: block;
  }

  .table-summary {
    display: none;
  }
  .pagination-google {
    width: 280px;
  }
  .link-page {
    padding: 0px 4.5px;
  }
}
