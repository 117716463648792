.audit #g-recaptcha > div {
  margin-left: auto;
  margin-right: auto;
}

.info-stats {
  max-width: 900px;
  /* display: flex; */
  /* justify-content: center; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 4px;
}

.stats-des {
  font-size: 17px;
}

.stats-figure {
  font-size: 22px;
  font-weight: 600;
}

.stats-picto {
  height: 50px;
}
.stats-picto > img {
  width: 40px;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
}

.logo-client {
  padding: 10px 30px;
}

.ant-row.ant-form-item {
  margin-bottom: 5px;
}

.ant-input.settings-input {
  height: 38px;
  border-radius: 2px;
  font-size: 14px;
}

.ant-input.settings-input::placeholder {
  font-size: 14px;
}

.ant-input-number.settings-input {
  border-radius: 2px;
  width: 50%;
}

.submit-form {
  background-color: #ff4e4e;
  border-color: #ff4e4e;
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
}

.submit-form:hover {
  background-color: #fff;
  border-color: #ff4e4e;
  color: #ff4e4e;
}

.logo-form {
  display: flex;
  align-items: center;
  margin-top: 6px;
  justify-content: flex-end;
  opacity: 0.5;
}

.logo-form img {
  width: 20px;
  margin: 0px 3px 0px 10px;
}

@media screen and (max-width: 991px) {
  .logos {
    display: block;
  }
  .logo-form img {
    margin: 10px 3px 0px 10px;
  }
}
