.input-row {
  margin-top: 60px;
}

.loading-message {
  text-align: center;
  font-size: 25px;
  color: #3c3c3c;
  margin-top: 20px;
}

.loading-message-serp {
  text-align: center;
  font-size: 25px;
  color: #3c3c3c;
  margin-top: 80px;
}

div.form {
  display: block;
  text-align: center;
}

form .form-audit {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

input {
  height: 40px;
}

button {
  height: 40px;
}

.input-businessName {
  border: none;
  width: 500px;
  border-radius: 3px 0px 0px 3px;
  padding-left: 20px;
}

.google-places-autocomplete__input {
  border: none;
  width: 500px;
  border-radius: 3px 0px 0px 3px;
  padding-left: 20px;
  height: 60px;
  font-size: 17px;
}

.suggestions-container {
  background-color: #fff;
  color: #2b4763;
  border-radius: 2px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.suggestion {
  text-align: left;
  padding: 5px;
}

.suggestion:hover {
  cursor: pointer;
  background-color: rgb(255, 123, 123);
  color: #fff;
}

.suggestion > i {
  padding-right: 5px;
}

.submit-button {
  border-radius: 0px 3px 3px 0px;
  width: 120px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  height: 60px;
  font-size: 17px;
}

.submit-button:hover {
  cursor: pointer;
}

.ant-select {
  width: 200px;
  height: 40px;
}

.submit-button:hover {
  background-color: rgb(255, 123, 123);
}

.result {
  margin-top: 90px;
}

.form-audit .ant-col-12 {
  width: auto;
}

.result > Row {
  padding: 10px 80px;
}

.result-phrase {
  color: #fff;
  font-size: 25px;
  text-align: left;
  margin-top: 30px;
}

.container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.container > .companyInfo {
  width: 900px;
  height: fit-content;
  background-color: #fff;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  color: #3c3c3c;
  font-weight: bold;
  margin-top: 40px;
  padding-bottom: 30px;
  box-shadow: 0 1px 1px 0 rgba(114, 114, 116, 0.12);
}

.container > .businessInfo.companyInfo {
  height: fit-content;
  padding: 20px;
}

div.progress {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.container > .companyGMB {
  margin-top: 30px;
}

.panel-header {
  color: #fff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #677294;
}
.ant-collapse {
  margin-top: 30px;
}

.ant-progress-circle .ant-progress-text {
  /* background-color: #52c41a30; */
  padding-top: 40px;
  padding-bottom: 40px;
  text-shadow: 0 0 22px;
}

.ant-select-selection--single .ant-select-selection__rendered {
  padding: 3px;
}

.ant-select-selection--single {
  height: 40px;
}

.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d942;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d942;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.tag {
  padding: 5px 10px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  width: fit-content;
  margin: 10px auto 10px 0px;
  font-size: 14px;
}

.steps-improve {
  color: #3c3c3c;
}

.ant-collapse-content {
  color: #677294;
}

.contact {
  font-size: 16px;
  text-align: center;
}

.new-search-button {
  border-radius: 3px;
  width: fit-content;
  padding: 10px 20px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  margin: 20px auto;
}

.new-search-button:hover {
  background-color: rgb(255, 123, 123);
  color: #fff;
}

.cta-section {
  margin: 50px auto;
  text-align: center;
}

.get-competitors {
  border-radius: 3px;
  width: fit-content;
  padding: 0px 20px;
  background-color: #ff4e4e;
  border: 0px;
  font-weight: bold;
  color: #fff;
  margin: 20px auto;
  font-size: 15px;
}

.table-competitors {
  width: 100%;
  margin-top: 30px;
}

.header-table {
  color: #fff;
  font-size: 15px;
}

.form-audit .input-row {
  display: inline-block;
}
@media screen and (max-width: 991px) {
  .form-audit .input-row {
    display: grid;
  }
  .form-audit .ant-col-12 {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-col-6 {
    width: 100%;
  }
  .ant-col-18 {
    margin-top: 20px;
    width: 100%;
  }
  .company-description {
    text-align: center;
  }
  .input-col {
    width: 100%;
  }
  .tag {
    margin-left: auto;
    margin-right: auto;
  }
  .input-businessName {
    width: 300px;
    border-radius: 3px;
    margin-bottom: 5px;
  }
  .google-places-autocomplete__input {
    width: 300px;
    border-radius: 3px;
  }
  .submit-button {
    width: 300px;
    border-radius: 3px;
  }
  .suggestions-container {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .container > .companyInfo {
    width: 90%;
    padding: 30px 10px;
  }
}
