.local-serp form {
  margin-top: 70px;
  margin-bottom: 70px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.ant-form-item-label {
  display: none;
}

.local-serp input {
  height: 50px;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
  border: none;
}

.ant-input {
  font-size: 17px;
}
.ant-input:focus,
.ant-input:active {
  border-color: #ff4e4e !important;
  box-shadow: none;
}

.ant-input::placeholder {
  color: grey;
  font-size: 17px;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #ff4e4e;
  border-color: #ff4e4e;
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #ff4e4e;
  border-color: #ff4e4e;
  box-shadow: none;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #ff4e4e;
}

.ant-radio-button-wrapper:hover {
  color: #ff4e4e;
}

.ant-select-selection--single {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-select-selection:hover {
  border-color: #f9727247;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f9727247;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #f9727247;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #f9727247;
}

.ant-select-selection-selected-value {
  font-size: 17px;
}

.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  box-shadow: none;
}

.ant-select-selection {
  border: none;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.09);
}

.ant-radio-button-wrapper {
  height: 50px !important;
  padding-top: 5px;
}

.ant-btn-primary {
  background-color: #ff4e4e;
  border-color: #ff4e4e;
  font-size: 17px;
  border-radius: 2px;
  box-shadow: none;
  height: 50px;
  width: 200px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background-color: rgb(255, 123, 123);
  border-color: rgb(255, 123, 123);
}

@media screen and (max-width: 991px) {
  .local-serp form {
    width: fit-content;
    padding: 0px 20px;
  }
}
