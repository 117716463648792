.company-description {
  text-align: left;
}

.avatar {
  margin-top: 15px;
}

.title-card {
  color: #3c3c3c;
  font-size: 22px;
  padding: 20px 40px;
}

.body-card {
  padding: 10px 40px 30px 40px;
  color: #677294;
}

.cta-card {
  padding: 30px;
}
.valid-logo {
  width: 18px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #ff4e4e;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #ff4e4e;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #ff4e4e;
}

.ant-tabs-ink-bar {
  background-color: #ff4e4e4d !important;
}

:root .ant-tabs-tab-prev-icon-target,
:root .ant-tabs-tab-next-icon-target {
  color: #ff4e4e;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 195px);
  grid-gap: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.card-stat {
  padding: 20px;
  border: 1px solid #8080802e;
  border-radius: 3px;
  text-align: center;
}
.green {
  color: #0ace6a;
}

.red {
  color: #ff4e4e;
}

.orange {
  color: #ffa300;
}

.criteria {
  font-size: 30px;
  margin-top: 30px;
}

.block-audit {
  padding: 10px 30px;
}

.title-secondary {
  margin: 30px 0px;
}

@media screen and (max-width: 991px) {
  .company-description {
    text-align: center;
  }
  .cards {
    display: block;
  }
  .card-stat {
    margin-bottom: 20px;
  }
  .criteria {
    margin-top: 20px;
  }
}

.local-audit-container > .company-info > .heading-audit {
  font-size: 18px;
  opacity: 0.8;
}

.local-audit-container > .company-info > .heading-audit > .company-name {
  opacity: 1;
  color: #000000;
}

.date-data {
  font-size: 14px;
  float: right;
}

.fetch-date {
  display: inline-block;
  font-weight: 300;
}

.update-audit {
  border: 0;
  background-color: transparent;
  padding: 5px;
  margin-left: 10px;
  box-shadow: none;
}

.update-audit:hover {
  background-color: transparent;
  font-weight: bold;
}

.update-audit:active {
  background-color: transparent;
}

.featureblock-container {
  box-shadow: rgba(114, 114, 116, 0.12) 0px 1px 1px 0px;
  /* padding: 30px 20px; */
  border-radius: 2px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  height: fit-content;
  border-radius: 5px;
  margin-bottom: 20px;
}

.featureblock-container-info {
  box-shadow: rgba(114, 114, 116, 0.12) 0px 1px 1px 0px;
  /* padding: 30px 20px; */
  border-radius: 2px;
  width: 100%;
  background-color: #fff;
  text-align: left;
  height: fit-content;
  border-radius: 5px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.criteria-status {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 200;
}

.sumup-category {
  margin: 30px 20px;
}

.progress-category {
  text-align: center;
}

.criteria-audit {
  margin: 20px;
  padding-top: 40px;
  border-top: 1px solid #8080801f;
}
.criteria-description {
  font-weight: 100;
  margin-bottom: 30px;
}
.short-link > input {
  width: 350px;
  margin-top: 20px;
  height: 40px !important;
}

.description-table {
  margin: 30px 0px;
}

.table-competitors-audit {
  margin: 30px 20px;
  border-top: 1px solid #8080801f;
  padding-top: 40px;
  /* margin: 40px 0px; */
}

.text-switch {
  padding-left: 10px;
  font-weight: 600;
}

.details-competitors-audit {
  border-top: 1px solid #8080801f;
  padding-top: 40px;
  margin: 40px 0px;
}

.audit-competitors {
  grid-template-columns: repeat(auto-fit, 400px);
  grid-gap: 20px;
  display: grid;
}

.chart-competitors {
  margin: 60px 20px;
  height: 300px;
}

.position-mapping {
  color: #000000;
  font-size: 16px;
}

.legend-graph {
  margin: 10px 0px;
  display: block;
  text-align: center;
}
.legend-square {
  width: 30px;
  height: 10px;
  border: 1px solid #ff4e4e;
  background-color: rgba(255, 77, 77, 0.58);
  display: inline-block;
}

.export-pdf > a {
  border: 1px solid #ff4e4e !important;
  color: #ff4e4e !important;
  background-color: transparent !important;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.export-pdf {
  display: flex;
  vertical-align: middle;
  float: right;
}

.duplicate-button {
  height: 50px;
  width: 200px;
  font-size: 15px;
}

.duplicates-found {
  font-size: 17px;
  font-weight: 600;
}

.refresh-duplicates {
  border: none !important;
  float: right;
  margin-top: -5px;
}

.featureblock-container {
  font-weight: 100;
}

.anticon.anticon-star.star {
  color: #ffa300;
}

.lock-overview-container {
  background-image: url("../FormLocalSearch//assets//audit/locker.png");
  background-size: cover;
  height: 200px;
  display: inline-block;
  text-align: center;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
  /* padding: 20px; */
}

.locked-btn {
  margin: 60px 0px 20px 0px;
  align-items: center;
  color: #fff;
  background-color: #ff4e4e;
  border: 1px solid #ff4e4e;
  height: 45px;
  font-weight: 600;
  font-size: 16px;
  width: fit-content !important;
}

.locked-btn:hover {
  color: #ff4e4e;
  background-color: #fff;
  border: 1px solid #ff4e4e;
  opacity: 0.6;
}

.report-link {
  font-size: 16px;
}

.carousel-img {
  display: flex !important;
  justify-content: center;
}
.carousel-section {
  display: block;
}
@media only screen and (max-width: 940px) {
  .export-pdf {
    float: left;
    margin-top: 5px;
  }
  .display-evolution {
    display: none;
  }
  .locked-btn {
    font-size: 14px;
  }
  .carousel-section {
    display: none;
  }
}
