.navbar {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 0 100px;
}

.logo {
  float: left;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

.logo > p {
  margin: 0px;
}

.logo > a {
  color: #fff;
}

.raq {
  float: right;
}

@media screen and (max-width: 991px) {
  .navbar {
    width: 100%;
    padding: 0px;
  }
  .logo {
    text-align: center;
    float: unset;
  }
  .raq {
    display: none;
  }
}
